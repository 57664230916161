import React from "react";
import ReactPlayer from "react-player";

const VideoThumbnail = ({ url }) => {
  return (
    <div className="thumbnail-container">
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        className="thumbnail-player"
      />
    </div>
  );
};

export default VideoThumbnail;
