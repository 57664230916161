import React, { useEffect } from "react";
import Layout from "../../layout";
import Video from "../DashboardHome/components/Video/index";
import Grid from "@mui/material/Grid";
import arrowIcon from "../../assets/images/arrowIcon.svg";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context";
import DummyVideoCard from "../../components/DummyVideoCard";

const ViewAllVideos = () => {
  const navigate = useNavigate();

  const { videos, user, onFindUserVideosFromFirebase } = useUserContext();

  useEffect(() => {
    onFindUserVideosFromFirebase(user && user.firebaseId);
  }, [videos]);

  return (
    <Layout>
      <div className="px-[60px]">
        <div className="pt-[60px] mb-[20px]">
          <div className="flex w-full ml-1">
            <img
              src={arrowIcon}
              alt=""
              className="h-4 mt-[11px] z-10 cursor-pointer"
              onClick={() => navigate(-1)}
            />

            {videos.length <= 10 ? (
              <h1 className="font-bold text-[24px] ml-8 font-cabinet-700">
                Videos -{" "}
                <span className="ml-1 font-cabinet-400">
                  {videos.length} / 10
                </span>
              </h1>
            ) : (
              <h1 className="font-bold text-[24px] ml-8 font-cabinet-700">
                {videos.length} Videos
                {/* <span className="ml-1 font-cabinet-400"></span> */}
              </h1>
            )}
          </div>
        </div>
        <Grid container spacing={4} className="h-full px-20 py-8">
          {videos && videos.length > 0 ? (
            videos.map((item, i) => (
              <Grid key={item.id} item lg={3} md={4} sm={6} xs={12}>
                <Video data={item} />
              </Grid>
            ))
          ) : (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DummyVideoCard />
            </Grid>
          )}
        </Grid>
      </div>
    </Layout>
  );
};

export default ViewAllVideos;
