import React from "react";
import videoImg from "../../assets/images/Group 76.svg";

const DummyVideoCard = () => {
  return (
    <div className="flex-shrink-0 min-w-full flex gap-7 cursor-pointer transition-all scale-[1] hover:scale-[1.05]">
      <div className="rounded-[20px] bg-white p-[20px] gap-[20px] flex flex-col items-center w-[336px]">
        <img src={videoImg} />
        <h1 className="text-[18px] font-inter-500">Create new Video</h1>
      </div>
    </div>
  );
};

export default DummyVideoCard;
