import React, { useRef, useState } from "react";
import {
  useParams,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import { HOME_PAGE_URL, PAYMENT_PLAN, URL } from "../../constants";
import arrowIcon from "../../assets/images/arrowIcon.svg";
import ShareIcon from "../../assets/images/ShareIcon.svg";
import DownloadIcon from "../../assets/images/download.svg";

import { auth, db } from "../../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import { useUserContext } from "../../context";
import CustomVideoPlayer from "./components/CustomVideoPlayer";
import HeaderLogo from "../../assets/images/logo.png";
import { Box, FormControl, MenuItem } from "@mui/material";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { Oval } from "react-loader-spinner";
import axios from "axios";

import { downloadVideo } from "../../progressbar/service";
import ProgressBar from "../../progressbar/ProgressBar";

const VideoPreview = () => {
  const navigate = useNavigate();

  const { pathname: route } = useLocation();

  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const { user, signOut, setLoading, onFindUserVideosFromFirebase } =
    useUserContext();

  const inputRef = useRef();

  const signOutRef = useRef();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const isExtension = searchParams.get("extension");

    if (isExtension) {
      onShareVideo();
    } else {
      return;
    }
  }, []);

  const [data, setData] = useState({
    id: "",
    name: "",
    video: "",
    duration: "",
    createdAt: "",
    updatedAt: "",
    userId: "",
  });

  const [changeValue, setChangeValue] = useState(false);
  const [heading, setHeading] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getSingleRecord();

    return () => {
      setData([]);
    };
  }, []);

  const getSingleRecord = async () => {
    const docRef = doc(db, "videos", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const { name, video, duration, createdAt, updatedAt, userId } =
        docSnap.data();
      setData({
        id: docSnap.id,
        name,
        video,
        duration,
        createdAt,
        updatedAt,
        userId,
      });
    } else {
      setData();
      console.log("No such document!");
    }
  };

  // if mp4 video geting error then webm video download

  const webmVideoDownload = async (mp4 = "") => {
    let url = mp4 ? mp4 : data?.video;
    // `url` is the download URL for 'images/stars.jpg'
    await fetch(url)
      .then((res) => res.blob())
      .then((result) => {
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(result);
        var tag = document.createElement("a");
        tag.href = imageUrl;
        tag.target = "_blank";
        tag.download = `diznote_${data?.id}.` + (mp4 ? "mp4" : "webm");
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });

    ///
  };

  const [loadingT, setLoadingT] = useState(false);
  const [progressT, setProgressT] = useState(0);

  const progress = ({ loaded, total }) => {
    let percentage = Math.round((loaded * 100) / total);
    console.log(percentage, "--percentage download");
    setProgressT(percentage);
  };

  // video download
  const onDownloadVideo = async (mp4 = "") => {
    /// xxxxxxxxxxxxxxxxxxxxx
    // const response = await fetch(data?.video);
    setLoadingT(true);
    setLoader(true);

    let link = decodeURIComponent(data?.video)?.split("?")[0];
    let getVideoName = link?.split("/")?.pop();

    /// xxxxxxxxxxxxxxxxxxxxx
    // setProgress('progress-in')
    if (getVideoName) {
      let url = "https://video-convert-iovjvrkeja-uc.a.run.app/convert/"+getVideoName;

      // let QueryData = {
      //   webmFileName: getVideoName,
      // };

      let getresp = await axios.get(url, {
        headers: {
          Accept: "video/mp4",
          "Content-Type" : "application/json",
        },
        reportProgress: true, 
        observe: 'events',
        responseType: "blob",
        onUploadProgress: (progressEvent) => {

       

          const { loaded, total } = progressEvent;
          let percentage = Math.round((loaded * 100) / total);
          // const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
          // console.log("onUploadProgress", totalLength);

          // console.log(percentage, "--percentage download",totalLength);
          setProgressT(percentage); // you can use this to show user percentage of file downloaded
          if (percentage === 100) {
            setTimeout(() => {
              setLoadingT(false);
            }, 400);
          }
        },
        
        onDownloadProgress: (progressEvent) => {

          // let percentCompleted = Math.round(progressEvent.loaded * 100 / 
          // progressEvent.total);
          // console.log(progressEvent.lengthComputable);
          // console.log(percentCompleted);
          // const progress = progressEvent.loaded / progressEvent.event.target.getResponseHeader('Content-Length');

          const { loaded, total } = progressEvent;

          console.log(progressEvent.estimated, ' - - - - - - - content-length')

          console.log(loaded, ' - - - - - ', total , "--percentage percentCompleted");

          // Math.round(100 * (progressEvent.loaded || 1) / (progressEvent.total || 1))

          // let percentCompleted = Math.round(((progressEvent.loaded || 1) * 100) / (progressEvent.total || 1)); // you can use this to show user percentage of file downloaded

          // console.log(percentCompleted, "--percentage percentCompleted");

        //   const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');
				// console.log("onDownloadProgress", totalLength);

          
          let percentage = Math.round((loaded * 100) / progressEvent.loaded);
          setProgressT(percentage); // you can use this to show user percentage of file downloaded
          if (percentage === 100) {
            setTimeout(() => {
              setLoadingT(false);
            }, 400);
          }
        },
      });
      
      //------using fetch ------------------
      // let data = JSON.stringify({
      //   webmFileName: getVideoName,
      // });
      // const response = await fetch(url, {
      //   method: "POST",
      //   body: data,
      //   headers :{"Content-Type": "application/json", "Accept": "video/mp4",}
      // });

      // console.log(response,'----response');
      // const contentLength = response.headers.get("content-length");
      // const total = parseInt(contentLength, 10);
      // let loaded = 0;

      // const res = new Response(
      //   new ReadableStream({
      //     async start(controller) {
      //       const reader = response.body.getReader();
      //       for (;;) {
      //         const { done, value } = await reader.read();
      //         if (done) break;
      //         loaded += value.byteLength;
      //         progress({ loaded, total });
      //         controller.enqueue(value);
      //       }
      //       controller.close();
      //     },
      //   })
      // );
      // const getresp = await res.blob();

      //------using fetch end------------------

      //------------previous code----------------
      // let getresp = await downloadVideo(
      //   url,
      //   {
      //     webmFileName: getVideoName,
      //   },
      //   {
      //     headers: {
      //         Accept: 'video/mp4'
      //     },
      //     responseType: "blob", // default
      //     responseEncoding: 'utf8', // default

      //     onDownloadProgress: (progressEvent) => {
      //       const percentage = Math.round(
      //         (progressEvent.loaded * 100) / progressEvent.total
      //       );
      //       setProgressT(percentage);
      //       if (percentage === 100) {
      //         setTimeout(() => {
      //           setLoadingT(false);
      //         }, 400);
      //       }
      //     },
      //   }
      // );
      setLoader(false);

      if (getresp?.status !== 200) {
        toast.error(getresp?.data?.error);
      }

      // console.log(new Blob([getresp.data]));
      // myUrl = (window.URL || window.webkitURL).createObjectURL( new Blob([response.data]) ); // response.data.data

      var urlCreator = window.URL || window.webkitURL;
      var imageUrl = urlCreator.createObjectURL(new Blob([getresp.data]));
      var tag = document.createElement("a");
      tag.href = imageUrl;
      tag.target = "_blank";
      tag.download = `diznote_${data?.id}.mp4`;
      document.body.appendChild(tag);
      tag.click();
      document.body.removeChild(tag);
      setLoader(false);

      // console.log(getresp, ' --- getresp')

      // getresp?.data?.videoUrl && getresp?.data?.videoUrl?.startsWith("https://")
      //   ? webmVideoDownload(getresp?.data?.videoUrl)
      //   : webmVideoDownload();
    } else webmVideoDownload();

    // webmVideoDownload()
  };

  const onShareVideo = () => {
    navigator.clipboard.writeText(
      window.location.href.replace("?extension=true", "")
    );
    toast.success("Video link is copied to the clipboard.");
  };

  const onHeadingChange = () => {
    setChangeValue(!changeValue);
    setHeading(!heading);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        changeValue &&
        inputRef.current &&
        !inputRef.current.contains(e.target)
      ) {
        onHeadingChange();
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [changeValue]);

  const onUpdateVideoTitle = async (e) => {
    e.preventDefault();
    setChangeValue(false);

    try {
      setLoading(true);

      const videoRef = doc(db, "videos", data.id);
      await updateDoc(videoRef, {
        ...data,
        updatedAt: new Date().toISOString(),
      });
      await onFindUserVideosFromFirebase(data.userId);
      toast.success("Video updated successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        open &&
        signOutRef.current &&
        !signOutRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const onShow = () => {
    setOpen(!open);
  };

  const onSignOut = () => {
    signOut(auth);
    window.location.href = HOME_PAGE_URL;
  };

  return (
    <div className="flex flex-col mb-4 px-16">
      {loadingT && <ProgressBar percentage={progressT} />}

      <div className="rounded-[10px] transition-all w-full my-2 mb-5 py-2 px-10 border border-[#dedede] flex justify-between items-center">
        <div className="flex w-full gap-5 items-center">
          <div className="w-[58px] mr-3 h-full flex items-center">
            <img
              onClick={() => navigate(URL.DASHBOARD_HOME.BASE)}
              className="h-[58px] w-[58px] cursor-pointer"
              src={HeaderLogo}
              alt="Logo"
            />
          </div>

          <img
            className="h-3 cursor-pointer"
            src={arrowIcon}
            alt=""
            onClick={() => navigate(URL.HOME.BASE)}
          />
          {changeValue ? (
            <div className="w-full" ref={inputRef}>
              <form
                className="w-full relative flex items-center"
                onSubmit={onUpdateVideoTitle}
              >
                <input
                  value={data.name}
                  onChange={(e) => setData({ ...data, name: e.target.value })}
                  className="text-[12px] w-full xl:text-[16px] font-inter-400 outline-none bg-transparent rounded-sm transition-all rounded-l-[10px]"
                  style={{ paddingRight: "100px" }}
                />
                <button
                  type="submit"
                  className="text-[12px] absolute right-5 z-20 text-[#5B34FF] xl:text-[14px] font-inter-400 cursor-pointer bg-transparent rounded-sm p-[5px] xl:p-[9px] px-5 xl:px-7 rounded-r-[10px]"
                >
                  Save
                </button>
              </form>
            </div>
          ) : (
            <h1
              onClick={onHeadingChange}
              className="text-[16px] font-inter-400 cursor-pointer"
            >
              {data.name || "Loading..."}
            </h1>
          )}
        </div>
        <div className="w-full flex items-center justify-end gap-5">
          <button
            disabled={loader ? true : false}
            className="justify-center font-inter-500 gap-[10px] hover:bg-white transition-all text-[14px] relative z-[100000] px-8 py-[10px] text-[#5B34FF] border border-[#5B34FF] rounded-[10px] flex items-center h-[43px]"
            onClick={onDownloadVideo}
          >
            {loader ? (
              <>
                <Oval
                  height={20}
                  width={20}
                  color="#000"
                  wrapperStyle={{}}
                  wrapperclassName=""
                  visible={true}
                  ariaLabel="oval-loading"
                  secondaryColor="#5B34FF"
                  strokeWidth={3}
                  strokeWidthSecondary={3}
                />
                Downloading
              </>
            ) : (
              <>
                <img src={DownloadIcon} alt="" />
                Download
              </>
            )}
          </button>

          <button
            className="justify-center font-inter-500 gap-[10px] hover:bg-white transition-all text-[14px] relative z-[100000] px-8 py-[10px] text-[#5B34FF] border border-[#5B34FF] rounded-[10px] flex items-center h-[43px]"
            onClick={onShareVideo}
          >
            <img src={ShareIcon} alt="Share Icon" />
            Share
          </button>

          {![URL.HOME.ROUTE].includes(route) && user ? (
            <div className="h-full flex items-center">
              {user.paymentPlan === PAYMENT_PLAN.FREE && (
                <button
                  className="whitespace-nowrap bg-[#000000] hover:bg-[#333] mx-10 px-6 py-3 transition-all rounded-[10px] text-white text-[16px] font-bold"
                  onClick={() => navigate(URL.PAYMENT.BASE)}
                >
                  <RocketLaunchIcon
                    sx={{
                      marginRight: "12px",
                      fontSize: "19.5px",
                    }}
                  />
                  Upgrade
                </button>
              )}
              <div className="w-[45px] mr-1">
                {user && user.avatar !== null ? (
                  <LazyLoadImage
                    onClick={() => onShow()}
                    className="w-[45px] h-[45px] object-cover object-center rounded-full cursor-pointer"
                    src={user.avatar}
                    loading="lazy"
                  />
                ) : (
                  <svg
                    onClick={() => onShow()}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 101 101"
                    id="user"
                    className="w-[45px] h-[45px] mx-5 cursor-pointer rounded-md"
                  >
                    <path d="M50.4 54.5c10.1 0 18.2-8.2 18.2-18.2S60.5 18 50.4 18s-18.2 8.2-18.2 18.2 8.1 18.3 18.2 18.3zm0-31.7c7.4 0 13.4 6 13.4 13.4s-6 13.4-13.4 13.4S37 43.7 37 36.3s6-13.5 13.4-13.5zM18.8 83h63.4c1.3 0 2.4-1.1 2.4-2.4 0-12.6-10.3-22.9-22.9-22.9H39.3c-12.6 0-22.9 10.3-22.9 22.9 0 1.3 1.1 2.4 2.4 2.4zm20.5-20.5h22.4c9.2 0 16.7 6.8 17.9 15.7H21.4c1.2-8.9 8.7-15.7 17.9-15.7z"></path>
                  </svg>
                )}
                {open ? (
                  <Box
                    ref={signOutRef}
                    sx={{
                      minWidth: 120,
                      position: "absolute",
                      top: "103px",
                      backgroundColor: "white",
                      height: "fit-content",
                      width: "170px",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      transition: "opacity 0.3s ease-in-out",
                      opacity: 1,
                      zIndex: "1",
                      marginLeft: "20px",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                      padding: "10px 8px",
                    }}
                  >
                    <FormControl fullWidth>
                      <MenuItem
                        onClick={() => navigate(URL.PAYMENT.BASE)}
                        value={10}
                      >
                        <h1 className="font-cabinet-700">Payment Plan</h1>
                      </MenuItem>
                      <MenuItem
                        sx={{ color: "red" }}
                        onClick={() => onSignOut()}
                        value={10}
                      >
                        <h1 className="font-cabinet-700">Sign Out</h1>
                      </MenuItem>
                    </FormControl>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      minWidth: 120,
                      position: "absolute",
                      top: "103px",
                      backgroundColor: "white",
                      height: "60px",
                      width: "170px",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      transition: "opacity 0.3s ease-in-out",
                      opacity: 0,
                      zIndex: "1",
                      marginLeft: "20px",
                      color: "#bf94fa",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                    }}
                  />
                )}
              </div>

              <div className="flex flex-col gap-0 justify-center">
                <h1 className="text-[20px] whitespace-nowrap leading-none font-cabinet-700">
                  {user && user.name !== null ? user.name : "User"}
                </h1>
                <p className="text-sm font-cabinet-400">
                  {user && user.paymentPlan} Plan
                </p>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="h-auto mb-2 w-full bg-transparent transition-all">
        <CustomVideoPlayer data={data} />
      </div>
    </div>
  );
};

export default VideoPreview;
