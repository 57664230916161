import React from "react";

const PricingData = ({ data }) => {
  return (
    <div
      className={`h-[47vh] w-[450px] ${
        data.isBusiness ? "bg-[#d1d1f7]" : "bg-white"
      } rounded-[40px] shadow-lg shadow-[#dac0e8]`}
    >
      <div className="p-12">
        <h2 className="text-[33px] font-inter-700">{data.plan}</h2>
        <p className="text-[#3c3d85] font-inter-700 text-[16px] pt-2">
          ${data.price} USD / monthly
        </p>
        <div className="w-full min-h-[100px]">
          {data.features.map((item, index) => (
            <div className="text-[#9a99ab] pt-2 relative" key={index}>
              <p className="mx-3">
                <span className="text-[#019aff] font-inter-700 text-[25px] absolute top-[-6px] left-0">
                  .
                </span>{" "}
                {item}
              </p>
            </div>
          ))}
        </div>
        {data.paid ? (
          <button
            className="rounded-3xl bg-gray-700 text-white font-inter-700 py-4 px-6 my-7 scale-[1] hover:scale-[1.1] transition-all"
            onClick={() => {
              window.location.href = data.link;
            }}
          >
            Purchased
          </button>
        ) : (
          <button
            className="rounded-3xl bg-[#009aff] text-white font-inter-700 py-4 px-6 my-7 scale-[1] hover:scale-[1.1] transition-all"
            onClick={() => {
              window.location.href = data.link;
            }}
          >
            Get Started
          </button>
        )}
      </div>
    </div>
  );
};

export default PricingData;
