import { AES, enc } from "crypto-js";

export const decryptToken = (token) => {
  const secretKey = process.env.REACT_APP_SECURITY_KEY;

  try {
    const decryptedBytes = AES.decrypt(token, secretKey);
    const decryptedText = decryptedBytes.toString(enc.Utf8);
    return JSON.parse(decryptedText);
  } catch (error) {
    console.error("Error decrypting token:", error);
    return null;
  }
};
