import React, { useEffect, useRef, useState } from "react";
import styles from "./Header.module.css";
import HeaderLogo from "../../assets/images/logo.png";
import iconBell from "../../assets/images/Icon.png";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { HOME_PAGE_URL, PAYMENT_PLAN, URL } from "../../constants";
import { auth } from "../../firebase";
import { useUserContext } from "../../context";
import { LazyLoadImage } from "react-lazy-load-image-component";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";

export const Header = ({ route }) => {
  const navigate = useNavigate();
  const { user, signOut } = useUserContext();

  const signOutRef = useRef();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        open &&
        signOutRef.current &&
        !signOutRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const onShow = () => {
    setOpen(!open);
  };

  const onSignOut = () => {
    signOut(auth);
    window.location.href = HOME_PAGE_URL;
  };

  return (
    <div
      className={`flex justify-between items-center px-16 ${styles.headerContainer}`}
    >
      <div
        id="record_btn"
        className="fixed right-20 top-20 bg-transparent"
      ></div>
      <div className="h-full flex items-center">
        <img
          onClick={() => navigate(URL.DASHBOARD_HOME.BASE)}
          className="h-[58px] w-[58px] cursor-pointer"
          src={HeaderLogo}
          alt="Logo"
        />
      </div>

      {route !== URL.HOME.ROUTE && user ? (
        <div className="h-full flex items-center">
          {user.paymentPlan === PAYMENT_PLAN.FREE && (
            <button
              className="whitespace-nowrap bg-[#000000] hover:bg-[#333] mx-10 px-6 py-3 transition-all rounded-[10px] text-white text-[16px] font-bold"
              onClick={() => navigate(URL.PAYMENT.BASE)}
            >
              <RocketLaunchIcon
                sx={{
                  marginRight: "12px",
                  fontSize: "19.5px",
                }}
              />
              Upgrade
            </button>
          )}
          {/* <img className="h-[34px] w-[30px]" src={iconBell} alt="" /> */}
          <div className="w-[45px] mr-1">
            {user && user.avatar !== null ? (
              <LazyLoadImage
                onClick={() => onShow()}
                className="w-[45px] h-[45px] object-cover object-center rounded-full cursor-pointer"
                src={user.avatar}
                loading="lazy"
              />
            ) : (
              <svg
                onClick={() => onShow()}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 101 101"
                id="user"
                className="w-[45px] h-[45px] mx-5 cursor-pointer rounded-md"
              >
                <path d="M50.4 54.5c10.1 0 18.2-8.2 18.2-18.2S60.5 18 50.4 18s-18.2 8.2-18.2 18.2 8.1 18.3 18.2 18.3zm0-31.7c7.4 0 13.4 6 13.4 13.4s-6 13.4-13.4 13.4S37 43.7 37 36.3s6-13.5 13.4-13.5zM18.8 83h63.4c1.3 0 2.4-1.1 2.4-2.4 0-12.6-10.3-22.9-22.9-22.9H39.3c-12.6 0-22.9 10.3-22.9 22.9 0 1.3 1.1 2.4 2.4 2.4zm20.5-20.5h22.4c9.2 0 16.7 6.8 17.9 15.7H21.4c1.2-8.9 8.7-15.7 17.9-15.7z"></path>
              </svg>
            )}
            {open ? (
              <Box
                ref={signOutRef}
                sx={{
                  minWidth: 120,
                  position: "absolute",
                  top: "103px",
                  backgroundColor: "white",
                  height: "fit-content",
                  width: "170px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  transition: "opacity 0.3s ease-in-out",
                  opacity: 1,
                  zIndex: "1",
                  marginLeft: "20px",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                  padding: "10px 8px",
                }}
              >
                <FormControl fullWidth>
                  <MenuItem
                    onClick={() => navigate(URL.PAYMENT.BASE)}
                    value={10}
                  >
                    <h1 className="font-cabinet-700">Payment Plan</h1>
                  </MenuItem>
                  <MenuItem
                    sx={{ color: "red" }}
                    onClick={() => onSignOut()}
                    value={10}
                  >
                    <h1 className="font-cabinet-700">Sign Out</h1>
                  </MenuItem>
                </FormControl>
              </Box>
            ) : (
              <Box
                sx={{
                  minWidth: 120,
                  position: "absolute",
                  top: "103px",
                  backgroundColor: "white",
                  height: "60px",
                  width: "170px",
                  borderRadius: "10px",
                  display: "flex",
                  alignItems: "center",
                  transition: "opacity 0.3s ease-in-out",
                  opacity: 0,
                  zIndex: "1",
                  marginLeft: "20px",
                  color: "#bf94fa",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                }}
              />
            )}
          </div>

          <div className="flex flex-col gap-0 justify-center">
            <h1 className="text-[20px] leading-none font-cabinet-700">
              {user && user.name !== null ? user.name : "User"}
            </h1>
            <p className="text-sm font-cabinet-400">
              {user && user.paymentPlan} Plan
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
