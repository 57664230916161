import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import SearchBar from "../../components/SearchBar";
import { URL } from "../../constants";
import Video from "./components/Video/index";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../context";
import DummyVideoCard from "../../components/DummyVideoCard";
import { ArrowUpward } from "@mui/icons-material";
import LOGO from "../../assets/images/logo.png";
import Joyride from "react-joyride";
import { Grid } from "@mui/material";

const DashboardHome = () => {
  const navigate = useNavigate();

  const { onFindUserVideosFromFirebase, videos, user } = useUserContext();

  const [showTour, setShowTour] = useState(false);

  useEffect(() => {
    onFindUserVideosFromFirebase(user && user.firebaseId);
  }, [videos]);

  const onRecordingShow = () => {
    setShowTour(true);
  };

  return (
    <Layout>
      {showTour && (
        <Joyride
          locale={{
            last: "Close",
          }}
          styles={{
            options: {
              primaryColor: "#000",
            },
          }}
          showProgress={false}
          steps={[
            {
              target: "#record_btn",
              disableBeacon: true,
              content: (
                <div className="my-[-20px] relative flex items-center flex-col">
                  <div className="flex">
                    <img className="h-16" src={LOGO} />
                    <div className="p-4 rounded-full absolute top-[-110px] right-28 bg-white">
                      <ArrowUpward
                        sx={{ fontSize: "40px", fontWeight: "400" }}
                      />
                    </div>
                  </div>
                  <h1 className="mt-5">
                    Click this icon to start video recording.
                  </h1>
                </div>
              ),
            },
          ]}
          showCloseButton={false}
          continuous={true}
          run={true}
          autoStart={true}
          callback={(data) => {
            if (data.status === "finished") {
              setShowTour(false);
            }
          }}
        />
      )}
      <SearchBar heading={"Videos (0/100)"} addData={onRecordingShow} />
      <div className="px-8 mt-2 lg:px-16 w-full">
        <div className="flex justify-between">
          {videos.length <= 10 ? (
            <h1 className="font-bold text-[24px] ml-8 font-cabinet-700">
              Videos -{" "}
              <span className="ml-1 font-cabinet-400">
                {videos.length} / 10
              </span>
            </h1>
          ) : (
            <h1 className="font-bold text-[24px] ml-8 font-cabinet-700">
              {videos.length} Videos
              {/* <span className="ml-1 font-cabinet-400"></span> */}
            </h1>
          )}

          <p
            onClick={() => navigate(URL.VIEW_ALL.BASE)}
            className="text-[20px] font-bold cursor-pointer mt-1 font-cabinet-700 hover:underline transition-all"
          >
            View All
          </p>
        </div>
        {/* <div className="flex gap-7 w-[100%] relative setOverflow bg-transparent">
          <div className="flex-shrink-0 flex gap-7 py-10 bg-transparent">
            {videos && videos.length > 0 ? (
              videos.map((data) => <Video key={data.id} data={data} />)
            ) : (
              <DummyVideoCard />
            )}
          </div>
        </div> */}

        <Grid container spacing={4} className="h-full px-20 py-8">
          {videos && videos.length > 0 ? (
            videos.map((item, i) => (
              <Grid key={item.id} item lg={3} md={4} sm={6} xs={12}>
                <Video data={item} />
              </Grid>
            ))
          ) : (
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <DummyVideoCard />
            </Grid>
          )}
        </Grid>
      </div>
    </Layout>
  );
};

export default DashboardHome;
