import React from "react";
import Layout from "../../layout";
import { PRICING_DATA } from "./data";
import PricingData from "./components/PricingData";
import { useUserContext } from "../../context";

const PricingPlan = () => {
  const { user } = useUserContext();

  return (
    <Layout>
      <div className="w-full h-full flex justify-center items-center">
        <div>
          <h1 className="text-[#1b224b] text-center font-inter-700 text-[2rem] md:text-[3rem] transition-all">
            Choose a pricing plan
          </h1>
          <div className="flex gap-8 w-full justify-between transition-all my-12">
            {PRICING_DATA(user && user.paymentPlan).map((data) => {
              return <PricingData key={data.id} data={data} />;
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricingPlan;
