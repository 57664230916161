import React, { useEffect } from "react";
import SUCCESS_ICON from "../../assets/images/success.svg";
import { useNavigate, useParams } from "react-router-dom";
import { MESSAGE, PAYMENT_PLAN, URL } from "../../constants";
import { decryptToken } from "../../utils/decryptToken";
import { useUserContext } from "../../context";

const PaymentSuccess = () => {
  const { user, onUpdateUser } = useUserContext();
  const { plan, status } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (plan && status) {
      const payload = {
        ...user,
        paymentStatus: "paid",
        paymentPlan: PAYMENT_PLAN.FREE,
        paymentDate: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      if (
        decryptToken(status) === MESSAGE.SUCCESS &&
        decryptToken(plan) === PAYMENT_PLAN.BUSINESS
      ) {
        payload.paymentPlan = PAYMENT_PLAN.BUSINESS;
        onUpdateUser(payload);
        setTimeout(() => {
          navigate(URL.DASHBOARD_HOME.BASE);
        }, 5000);
      }

      if (
        decryptToken(status) === MESSAGE.SUCCESS &&
        decryptToken(plan) === PAYMENT_PLAN.ENTERPRISE
      ) {
        payload.paymentPlan = PAYMENT_PLAN.ENTERPRISE;
        onUpdateUser(payload);

        setTimeout(() => {
          navigate(URL.DASHBOARD_HOME.BASE);
        }, 5000);
      }
    }
  }, []);

  return (
    <section className="bg-white">
      <div className="container flex items-center min-h-screen px-6 py-12 mx-auto">
        <div className="flex flex-col items-center max-w-sm mx-auto text-center">
          <p className="p-3 text-sm font-medium text-indigo-600 rounded-full bg-blue-50">
            <img
              src={SUCCESS_ICON}
              alt="payment-success"
              className="w-[250px]"
            />
          </p>
          <h1 className="mt-10 text-2xl font-semibold text-gray-800 md:text-3xl">
            Payment Success
          </h1>
          <p className="mt-4 text-gray-500">Thank You for your payment.</p>
          <p className="mt-4 text-gray-500">
            You are being redirected. Don't close this page.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PaymentSuccess;
