import React from "react";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import AddTwoToneIcon from "@mui/icons-material/AddTwoTone";
import { DIZNOTE_HOME_PAGE_URL } from "../../constants";

function SearchBar({ heading, addData }) {
  return (
    <div className="flex flex-col items-center mb-0">
      {/* <div className="flex my-12 items-end gap-10 justify-between">
        <input
          className="lg:w-[45rem] w-[30rem] h-[60px] rounded-[10px] p-2 bg-[#F5F5F5] border-2 border-[#dddddd] pl-7 outline-none transition-all"
          placeholder="Search For Your Videos"
        />
        <button className="bg-[#000000] h-[60px] w-[174px] rounded-[10px] text-white text-[16px] font-bold">
          <RocketLaunchIcon
            sx={{
              marginRight: "12px",
              fontSize: "19.5px",
            }}
          />
          Upgrade
        </button>
      </div> */}
      <div className="w-[93%] flex justify-end items-center gap-5 my-14 mb-8">
        {/* <div className="flex justify-between items-center">
          <h1 className="font-bold text-[20px] border-b-4 border-[#5131d9]">
            {heading}
          </h1>
          <p className="font-normal text-[20px] ml-10">Screenshots</p>
        </div> */}
        <div
          className="gradient cursor-pointer text-white text-[16px] h-[60px] px-5 py-0 rounded-[10px] flex justify-center items-center gap-5"
          onClick={() => window.open(DIZNOTE_HOME_PAGE_URL)}
        >
          <span className="fa fa-chrome text-[24px]"></span>
          Install Chrome Extension
        </div>

        <button
          onClick={addData}
          className="px-3 py-0 h-[60px] gradient rounded-[10px] text-[white] font-cabinet-700 text-md"
        >
          <AddTwoToneIcon
            sx={{
              marginRight: "10px",
              marginTop: "-4px",
            }}
          />
          Start Recording Video
        </button>
      </div>
    </div>
  );
}

export default SearchBar;
