export const HOME_PAGE_URL = "https://dashboard.diznote.com"
export const DIZNOTE_HOME_PAGE_URL = "https://chromewebstore.google.com/detail/diznote-record-and-share/ldakgpijhgkhfdpnlapnnaendcmhkbje?hl=en-GB&utm_source=ext_sidebar"

export const URL = {
  NOT_FOUND_PAGE: "*",
  HOME: {
    ROUTE: "home",
    BASE: "/",
  },
  LOGIN: {
    ROUTE: "login",
    BASE: "login",
  },
  DASHBOARD_HOME: {
    ROUTE: "dashboard",
    BASE: "/dashboard",
  },
  UPLOAD_VIDEO: {
    ROUTE: "upload-video",
    BASE: "/dashboard/upload-video",
  },
  VIDEO_PREVIEW: {
    ROUTE: "video-preview",
    BASE: "/video/:id",
  },
  VIEW_ALL: {
    ROUTE: "view-all",
    BASE: "/dashboard/view-all",
  },
  PAYMENT: {
    ROUTE: "payment",
    BASE: "/dashboard/payment",
  },
  PAYMENT_SUCCESS: {
    ROUTE: "payment-success",
    BASE: "/dashboard/payment/success/:plan/:status",
  },
};

export const HTTP_STATUS_CODES = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  RESOURCE_NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
};

export const PAYMENT_PLAN = {
  FREE: "Free",
  BUSINESS: "Business",
  ENTERPRISE: "Enterprise",
};

export const MESSAGE = {
  SUCCESS: "Success",
  ERROR: "Error",
};

export const PAYMENT_STATUS = {
  PAID: "paid",
  UNPAID: "unpaid",
};

export const PAYMENT_TOKEN = {
  BUSINESS: "U2FsdGVkX19fkFRdvsPeakd4dQUxTg51QJHR6YbsWD0=",
  ENTERPRISE: "U2FsdGVkX19hHem+IeW36TAddKthvGP0eeeHt4+jSlo=",
  SUCCESS: "U2FsdGVkX1++WRi53vBXPQDsPDoPG0fM3nO4Bu9ypQ8=",
};
