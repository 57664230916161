import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyADFDHIbLcX-xSrSU6Hme00HR7zDjcY2fE",
  authDomain: "sidelink-4d816.firebaseapp.com",
  projectId: "sidelink-4d816",
  storageBucket: "sidelink-4d816.appspot.com",
  messagingSenderId: "515122417886",
  appId: "1:515122417886:web:e42bbd34861d780944b24f",
  measurementId: "G-TW0YMY893G",
};

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export default firebaseApp;
