import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaVolumeMute,
  FaExpand,
  FaCompress,
} from "react-icons/fa";
import "./index.css";

const CustomVideoPlayer = ({ data }) => {
  const [playing, setPlaying] = useState(false);
  const [volume, setVolume] = useState(1);
  const [muted, setMuted] = useState(false);
  const [played, setPlayed] = useState(0);
  const [duration, setDuration] = useState(0);
  const [seeking, setSeeking] = useState(false);
  const [fullScreen, setFullScreen] = useState(false);

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);

  const handlePlayPause = (e) => {
    e.stopPropagation();
    setPlaying(!playing);
  };

  const handleVolumeChange = (e) => {
    setVolume(parseFloat(e.target.value));

    if (parseFloat(e.target.value) * 100 !== 0) {
      setMuted(false);
    } else {
      setMuted(true);
    }
  };

  const handleToggleFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
      setFullScreen(false);
    } else if (playerContainerRef && playerContainerRef.current) {
      playerContainerRef.current.requestFullscreen();
      setFullScreen(true);
    }
  };

  const onProgress = (state) => {
    if (!seeking) {
      setPlayed(state.playedSeconds);
    }
  };

  const onDuration = () => {
    setDuration(data && data.duration);
  };

  const handleSeekMouseDown = () => {
    setSeeking(true);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);

    if (playerRef.current) {
      playerRef.current.seekTo(parseFloat(e.target.value));
    }
  };

  const formatDuration = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    return [h, m > 9 ? m : `0${m}`, s > 9 ? s : `0${s}`]
      .filter((a) => a)
      .join(":");
  };

  const onEndVideo = () => {
    setPlaying(!playing);
    setPlayed(0);
  };

  const playedPercentage = (played / duration) * 100;

  return (
    <div
      className="video-player-container"
      ref={playerContainerRef}
      onClick={handlePlayPause}
    >
      <ReactPlayer
        className="react-player"
        ref={playerRef}
        config={{
          youtube: {
            playerVars: { showinfo: 1 },
          },
          file: {
            forceVideo: true,
            forceAudio: true,
          },
        }}
        width="100%"
        height="100%"
        progressInterval={100}
        url={data && data.video}
        muted={muted}
        volume={volume}
        playing={playing}
        onDuration={(state) => onDuration(state)}
        onProgress={(state) => onProgress(state)}
        onEnded={onEndVideo}
      />
      <div className="player-controls" onClick={(e) => e.stopPropagation()}>
        <button className="w-[50px]" onClick={handlePlayPause}>
          {playing ? <FaPause /> : <FaPlay />}
        </button>
        <span>{formatDuration(played)}</span>

        <div className="w-full px-[30px] mx-auto">
          <input
            type="range"
            min={0}
            max={duration}
            step={1}
            value={played}
            className={
              "transition-all w-full h-[10px] rounded-full overflow-hidden outline-none cursor-pointer"
            }
            style={{
              background: `linear-gradient(to right, #5B34FE 0%, #5B34FE ${playedPercentage}%, #f3f3f7 ${playedPercentage}%, #f3f3f7 100%)`,
            }}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
          />
        </div>

        <span>{formatDuration(duration)}</span>

        <div className="volumeSliderContainer mx-5 cursor-pointer w-[50px] h-fit px-0 py-[10px] flex justify-center items-center gap-[10px] relative top-0">
          <button className="w-fit">
            {muted ? (
              <svg
                className="w-[24px] h-[24px]"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                <line x1="23" y1="9" x2="17" y2="15"></line>
                <line x1="17" y1="9" x2="23" y2="15"></line>
              </svg>
            ) : (
              <svg
                className="w-[24px] h-[24px]"
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
              </svg>
            )}
          </button>

          <div className="volumeSlider">
            <input
              type="range"
              min={0}
              max={1}
              step="any"
              value={volume}
              onChange={handleVolumeChange}
              className="transition-all w-full h-[10px]  rounded-full overflow-hidden outline-none cursor-pointer"
              style={{
                background: `linear-gradient(to right, #5B34FE 0%, #5B34FE ${
                  volume * 100
                }%, #f3f3f7 ${volume * 100}%, #f3f3f7 100%)`,
              }}
            />
            <span>{Math.floor(volume * 100)}%</span>
          </div>
        </div>

        <button onClick={handleToggleFullscreen}>
          {fullScreen ? (
            <svg
              className="w-[20px] h-[20px]"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"></path>
            </svg>
          ) : (
            <svg
              className="w-[20px] h-[20px]"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"
              ></path>
            </svg>
          )}
        </button>
      </div>
    </div>
  );
};

export default CustomVideoPlayer;
