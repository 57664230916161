import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./styles/css/index.css";
import "./assets/styles/font-awesome.css";
import MainRouter from "./routes";
import { PropsProvider } from "./context/userContext";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <PropsProvider>
    <BrowserRouter>
      <MainRouter />
    </BrowserRouter>
  </PropsProvider>
);
