import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../../constants";
import { useUserContext } from "../../../../context";
import videoImage from "../../../../assets/images/VideoImg.svg";
import convertTime from "../../../../utils/convertTime";
import { format } from "timeago.js";
import { Box, FormControl, MenuItem } from "@mui/material";
import { MoreHoriz, Link } from "@mui/icons-material";
import { toast } from "react-hot-toast";
import { SplitURL } from "../../../../utils/splitURL";
import { db } from "../../../../firebase";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

import VideoThumbnail from "../../../../components/VideoThumbnail";

const Video = ({ data }) => {
  const navigate = useNavigate();
  const { user, onFindUserVideosFromFirebase, setLoading } = useUserContext();

  const inputRef = useRef();

  const [videoData, setVideoData] = useState({
    id: data.id,
    name: data.name,
    video: data.video,
    duration: data.duration,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
    userId: data.userId,
  });

  const [onShow, setOnShow] = useState(false);
  const [moreActions, setMoreActions] = useState(false);
  const [linkActions, setLinkActions] = useState(false);
  const [dropDownShow, setDropDownShow] = useState(false);
  const [changeValue, setChangeValue] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        changeValue &&
        inputRef.current &&
        !inputRef.current.contains(e.target)
      ) {
        setChangeValue(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [changeValue]);

  const navigateToVideoScreen = () => {
    navigate(`${URL.VIDEO_PREVIEW.BASE.replace(":id", data.id)}`);
  };

  const onDropDownShow = (e) => {
    e.stopPropagation();
    setDropDownShow(!dropDownShow);
    setOnShow(false);
  };

  const handleRename = (e) => {
    e.stopPropagation();
    setChangeValue(true);
    setDropDownShow(false);
  };

  const onCopyLink = (e) => {
    e.stopPropagation();
    const urlSplit = SplitURL(window.location.href);
    navigator.clipboard.writeText(
      `${urlSplit[0]}//${urlSplit[2]}${URL.VIDEO_PREVIEW.BASE.replace(
        ":id",
        data.id
      )}`
    );
    toast.success("Link copied successfully");
  };

  const onUpdateVideoTitle = async (e) => {
    e.preventDefault();
    setChangeValue(false);

    try {
      setLoading(true);

      const videoRef = doc(db, "videos", videoData.id);

      await updateDoc(videoRef, {
        ...videoData,
        updatedAt: new Date().toISOString(),
      });
      await onFindUserVideosFromFirebase(videoData.userId);

      toast.success("Video updated successfully");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onDeleteVideo = async (e) => {
    e.stopPropagation();
    setDropDownShow(false);

    try {
      setLoading(true);

      let link = decodeURIComponent(videoData?.video)?.split("?")[0];
      let getVideoName = link?.split("/")?.pop();

      // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = //
        // delete webm video
      // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = //

      const videoRef = doc(db, "videos", videoData.id);
      await deleteDoc(videoRef);
      await onFindUserVideosFromFirebase(videoData.userId);

      // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = //
        // delete webm video
      // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = //
      // - - - - - - - - - - - - - - - - - - - d - i - v - i - d - e - r - - - - - - - - - - - - - - - - //
      // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = //
        // delete mp4 video
      // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = //
      let storage = getStorage();
      const desertRef =  ref(storage, `mp4/${getVideoName.replace('.webm','.mp4')}`);
      await deleteObject(desertRef).then(e=>{}).catch(e=>console.error)
      // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = //
        // delete mp4 video
      // = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = = //

      toast.success("Video deleted successfully");
    } catch (error) {
      console.log(error)
      toast.error(error.message);
    }
  };

  return (
    <div
      className="w-full pb-2 transition-all rounded-[10px] bg-white cursor-pointer scale-[1] hover:scale-[1.05]"
      onMouseOver={() => setOnShow(true)}
      onMouseLeave={() => {
        setOnShow(false);
        setDropDownShow(false);
      }}
    >
      <div
        className="h-[200px] w-full relative cursor-pointer flex justify-center items-start"
        onClick={navigateToVideoScreen}
      >
        <VideoThumbnail url={videoData && videoData.video} />
        {/* <img
          className="h-full w-[100%] rounded-tl-[10px] rounded-tr-[10px] object-cover"
          src={(videoData && GetVideoThumbnail(videoData.video)) || videoImage}
          alt=""
        /> */}
        <div className="absolute bottom-3 right-3 w-[62px] h-6 rounded-[5px] bg-black flex items-center justify-center">
          <h1 className="font-bold text-[12px] text-white">
            {videoData && convertTime(videoData.duration)}
          </h1>
        </div>

        {onShow && (
          <div className="absolute top-3 right-3 z-[9999999]">
            <div
              onMouseOver={() => setLinkActions(true)}
              onMouseLeave={() => setLinkActions(false)}
              onClick={onCopyLink}
              className="h-7 w-7 rounded-full bg-white flex justify-center"
            >
              <Link sx={{ fontSize: "20px", marginTop: "4px" }} />
            </div>
            {linkActions && (
              <div className="border border-black rounded-3xl p-[6px] flex justify-center absolute top-0 right-9 bg-black opacity-[0.8] w-24">
                <p className="text-white font-cabinet-700 text-[12px]">
                  Copy Link
                </p>
              </div>
            )}
            <div
              onMouseOver={() => setMoreActions(true)}
              onMouseLeave={() => setMoreActions(false)}
              onClick={onDropDownShow}
              className="h-7 w-7 rounded-full bg-white flex justify-center mt-3"
            >
              <MoreHoriz sx={{ fontSize: "20px", marginTop: "4px" }} />
            </div>
            {moreActions && (
              <div className="rounded-3xl p-2 flex justify-center absolute top-9 right-9 bg-black opacity-[0.8] w-28">
                <p className="text-white font-cabinet-700 text-[12px]">
                  More Actions...
                </p>
              </div>
            )}
            {dropDownShow ? (
              <Box
                sx={{
                  minWidth: 120,
                  position: "absolute",
                  top: "80px",
                  right: "12px",
                  backgroundColor: "white",
                  width: "160px",
                  borderRadius: "5px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  transition: "opacity 0.3s ease-in-out",
                  opacity: 1,
                  zIndex: "1",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                  padding: "8px",
                }}
              >
                <FormControl fullWidth>
                  <MenuItem
                    sx={{
                      marginBottom: "2px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px",
                      paddingLeft: "8px",
                      width: "100%",
                      transition: "all",
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.048)",
                      },
                    }}
                    onClick={handleRename}
                  >
                    Rename
                  </MenuItem>
                  <MenuItem
                    sx={{
                      marginBottom: "2px",
                      borderRadius: "5px",
                      display: "flex",
                      alignItems: "center",
                      padding: "8px",
                      paddingLeft: "8px",
                      width: "100%",
                      transition: "all",
                      fontSize: "14px",
                      "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.048)",
                      },
                      color: "#ff0d0d",
                    }}
                    onClick={onDeleteVideo}
                  >
                    Delete
                  </MenuItem>
                </FormControl>
              </Box>
            ) : (
              <Box
                sx={{
                  minWidth: 120,
                  position: "absolute",
                  top: "80px",
                  right: "12px",
                  backgroundColor: "white",
                  width: "128px",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  transition: "opacity 0.3s ease-in-out",
                  opacity: 0,
                  zIndex: "1",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                  padding: "8px",
                }}
              />
            )}
            <div className="hover-capture" />
          </div>
        )}
      </div>
      <div className="my-5 mx-5 font-cabinet-700">
        {changeValue ? (
          <div className="w-full" ref={inputRef}>
            <form className="w-full flex gap-5" onSubmit={onUpdateVideoTitle}>
              <input
                value={videoData.name}
                onChange={(e) =>
                  setVideoData({ ...videoData, name: e.target.value })
                }
                className="w-full font-bold text-[20px] border-none outline-none"
              />
              <button
                type="submit"
                className="p-0 bg-transparent cursor-pointer text-black rounded-3xl text-sm"
              >
                Save
              </button>
            </form>
          </div>
        ) : (
          <h1 className="font-bold truncate text-[18px] cursor-pointer border-none outline-none">
            {videoData && videoData.name}
          </h1>
        )}
        <div className="flex gap-3 items-center mt-5">
          {user && user.avatar !== null ? (
            // eslint-disable-next-line jsx-a11y/alt-text
            <img
              src={user.avatar}
              className="w-[30px] h-[30px] object-cover object-center rounded-full cursor-pointer"
            />
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 101 101"
              id="user"
              className="h-7 w-7 cursor-pointer rounded-full"
            >
              <path d="M50.4 54.5c10.1 0 18.2-8.2 18.2-18.2S60.5 18 50.4 18s-18.2 8.2-18.2 18.2 8.1 18.3 18.2 18.3zm0-31.7c7.4 0 13.4 6 13.4 13.4s-6 13.4-13.4 13.4S37 43.7 37 36.3s6-13.5 13.4-13.5zM18.8 83h63.4c1.3 0 2.4-1.1 2.4-2.4 0-12.6-10.3-22.9-22.9-22.9H39.3c-12.6 0-22.9 10.3-22.9 22.9 0 1.3 1.1 2.4 2.4 2.4zm20.5-20.5h22.4c9.2 0 16.7 6.8 17.9 15.7H21.4c1.2-8.9 8.7-15.7 17.9-15.7z"></path>
            </svg>
          )}
          <h1 className="text-[14px] font-inter-600">
            {user && user.name !== null ? user.name : "User"}
          </h1>
        </div>
        <div className="flex w-full justify-end">
          <span className="text-[12px] font-inter-600">
            {videoData && format(new Date(videoData.createdAt), "en_US")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Video;
