import { PAYMENT_PLAN } from "../../../constants";

export const PRICING_DATA = (paymentPlan) => [
  {
    id: 1,
    isBusiness: true,
    plan: "Business",
    price: "19.99",
    features: ["Unlimited Videos", "Video Quality 60 FPS HD", "Drawing Tool"],
    link: "https://buy.stripe.com/cN26s5a9p6EKaWI6op",
    paid: paymentPlan === PAYMENT_PLAN.BUSINESS ? true : false,
  },
  {
    id: 2,
    isBusiness: false,
    plan: "Enterprise",
    price: "29.99",
    features: ["Everything in Business", "Video Quality 4K"],
    link: "https://buy.stripe.com/4gw6s5ftJ5AGgh24gg",
    paid: paymentPlan === PAYMENT_PLAN.ENTERPRISE ? true : false,
  },
];
