import React from "react";
import { Route, Routes } from "react-router-dom";
import { URL } from "../constants";
import { Toaster } from "react-hot-toast";
import Signup from "../screens/Signup";
import DashboardHome from "../screens/DashboardHome";
import VideoPreview from "../screens/VideoPreview";
import ViewAllVideos from "../screens/ViewAllVideos";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import PricingPlan from "../screens/PricingPlan";
import NotPageFound from "../screens/NotPageFound";
import PaymentSuccess from "../screens/PaymentSuccess";

const MainRouter = () => {
  return (
    <>
      <Toaster />
      <Routes>
        {/* Public Routes */}
        <Route path={URL.HOME.BASE} element={<PublicRoute />}>
          <Route path={URL.HOME.BASE} element={<Signup />} />
        </Route>

        {/* Private Routes */}
        <Route path={URL.DASHBOARD_HOME.BASE} element={<PrivateRoute />}>
          <Route path={URL.DASHBOARD_HOME.BASE} element={<DashboardHome />} />
          <Route path={URL.VIEW_ALL.BASE} element={<ViewAllVideos />} />
          <Route path={URL.PAYMENT.BASE} element={<PricingPlan />} />
          <Route path={URL.PAYMENT_SUCCESS.BASE} element={<PaymentSuccess />} />
        </Route>

        <Route path={URL.VIDEO_PREVIEW.BASE} element={<VideoPreview />} />
        <Route path={URL.NOT_FOUND_PAGE} element={<NotPageFound />} />
      </Routes>
    </>
  );
};

export default MainRouter;
