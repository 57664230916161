import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { URL } from "../../constants";
import { useUserContext } from "../../context";

const PrivateRoute = () => {
  const { authenticated, firebaseId } = useUserContext();

  return authenticated ? <Outlet /> : !firebaseId && <Navigate to={URL.HOME.BASE} />;
};

export default PrivateRoute;
