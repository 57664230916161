import React from "react";
import { Header } from "../components/Header";

const Layout = ({ children }) => {
  return (
    <div className="App">
      <Header />
      <div className="bg-[#f5f5f5] h-[87vh] overflow-y-auto overflow-x-hidden w-[100%]">
        {children}
      </div>
    </div>
  );
};

export default Layout;
