import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { URL } from "../../constants";
import { useUserContext } from "../../context";
const PublicRoute = () => {
  const { authenticated } = useUserContext();

  return authenticated ? <Navigate to={URL.DASHBOARD_HOME.BASE} /> : <Outlet />;
};

export default PublicRoute;
