import React, { useState } from "react";
import styles from "./Signup.module.css";
import GoogleLogo from "../../assets/images/Google.png";
import MicrosoftLogo from "../../assets/images/Microsoft.png";
import SignupRightBG from "../../assets/images/SignupRightBG.png";
import SignupFG from "../../assets/images/SignupFG.png";
import { Header } from "../../components/Header";
import { useUserContext } from "../../context";
import { URL } from "../../constants";

const Signup = () => {
  const { onLogIn, onSignUp, googleSignIn, microsoftSignIn } = useUserContext();

  const [isSignIn, setIsSignIn] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleCustomSignUp = async () => {
    if (email && password) {
      try {
        if (isSignIn) {
          await onLogIn(email, password);
        } else {
          await onSignUp(email, password);
        }
      } catch (error) {
        alert(`Error: ${error.message}`);
      }
    } else {
      alert("Please provide email and password");
    }
  };

  return (
    <>
      <Header route={URL.HOME.ROUTE} />
      <div className={styles.signupContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.signupHeading}>
            <div className={`${styles.headingNormal} font-cabinet-500`}>
              Record Your First
            </div>
            <div className={`${styles.headingBold} font-cabinet-700`}>
              Video
            </div>
          </div>
          <div className={styles.socialSignupBtns}>
            <div
              className={`${styles.signupWithGoogleBtn} font-inter-500`}
              onClick={googleSignIn}
            >
              <div>
                <img className={styles.socialIcon} src={GoogleLogo} alt="" />
              </div>
              <div>Sign up with Google</div>
            </div>
            <div
              className={`${styles.signupWithMicrosoftBtn} font-inter-500`}
              onClick={microsoftSignIn}
            >
              <div>
                <img className={styles.socialIcon} src={MicrosoftLogo} alt="" />
              </div>
              <div>Sign up with Microsoft</div>
            </div>
          </div>
          <div className={`${styles.ORLabel} font-inter-700`}>OR</div>
          <div className={`${styles.customLoginlabel} font-inter-500`}>
            {isSignIn ? "Sign In" : "Sign Up"} Through Email
          </div>
          <div className={styles.inputContainer}>
            <input
              type="email"
              placeholder="Email"
              className={`${styles.inputField} font-inter-500`}
              onChange={handleEmailChange}
            />
          </div>
          <div className={styles.inputContainer2}>
            <input
              type="password"
              placeholder="Password"
              className={`${styles.inputField} font-inter-500`}
              onChange={handlePasswordChange}
            />
          </div>
          <div className={styles.forgotPasswordContainer}>
            {isSignIn ? (
              <div
                className={`${styles.forgotPassword} font-inter-700`}
                onClick={() => setIsSignIn(false)}
              >
                Create new account?
              </div>
            ) : (
              <div
                className={`${styles.forgotPassword} font-inter-700`}
                onClick={() => setIsSignIn(true)}
              >
                Already have an account?
              </div>
            )}
          </div>
          <div
            className={`${styles.appButton} font-inter-700`}
            onClick={() => handleCustomSignUp()}
          >
            {isSignIn ? "Login" : "Continue"}
          </div>
        </div>
        <div className={styles.rightContainer}>
          <div className={styles.signupRightBGContainer}>
            <img src={SignupRightBG} alt="" />
          </div>
          <div className={styles.signupFGContainer}>
            <img className={styles.signupFG} src={SignupFG} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
