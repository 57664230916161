import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import {
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithEmailAndPassword,
  OAuthProvider,
  signOut as firebaseSignOut,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { UserContext } from ".";
import firebaseApp, { auth, db } from "../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { toast } from "react-hot-toast";
import Loader from "../components/Loader";
import { PAYMENT_PLAN, PAYMENT_STATUS, URL } from "../constants";
import { sortDataAccordingtoDate } from "../utils/sortData";

export const PropsProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["firebaseId"]);

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [firebaseId, setFirebaseId] = useState(cookies.firebaseId);
  const [user, setUser] = useState();
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (loading) {
      onLoad();
    }
  }, [loading]);

  useEffect(() => {
    if (firebaseId) {
      return setCookie("firebaseId", firebaseId, { path: "/" });
    }

    if (!authenticated) {
      onLoad();
    }
  }, [authenticated, firebaseId, user]);

  useEffect(() => {
    setAuthenticated(firebaseId ? true : false);
  }, [firebaseId, user]);

  useEffect(() => {
    if (firebaseId) {
      setCookie("firebaseId", firebaseId, { path: "/" });
    }
  }, [firebaseId]);

  const onLogIn = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential && userCredential.user) {
        setLoading(true);
        toast.success("Login successfully");
        setAuthenticated(true);
        setFirebaseId(userCredential.user.uid);
      } else {
        toast.error("Invalid credentials");
        setAuthenticated(false);
      }
    } catch (error) {
      toast.error(error.message);
      setAuthenticated(false);
    }
  };

  const onSignUp = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      if (userCredential && userCredential.user) {
        setLoading(true);
        toast.success("Register successfully");
        setAuthenticated(true);
        setFirebaseId(userCredential.user.uid);
      } else {
        toast.error("Invalid credentials");
        setAuthenticated(false);
      }
    } catch (error) {
      toast.error(error.message);
      setAuthenticated(false);
    }
  };

  const googleSignIn = async () => {
    try {
      const googleAuthProvider = new GoogleAuthProvider();
      const googleResponse = await signInWithPopup(auth, googleAuthProvider);

      if (googleResponse && googleResponse.user) {
        setLoading(true);
        toast.success("Login successfully");
        setAuthenticated(true);
        setFirebaseId(googleResponse.user.uid);
      } else {
        toast.error("Invalid credentials");
        setAuthenticated(false);
      }
    } catch (error) {
      toast.error(error.message);
      setAuthenticated(false);
    }
  };

  const microsoftSignIn = async () => {
    try {
      const microsoftProvider = new OAuthProvider("microsoft.com");
      const microsoftResponse = await signInWithPopup(auth, microsoftProvider);

      if (microsoftResponse && microsoftResponse.user) {
        setLoading(true);
        toast.success("Login successfully");
        setAuthenticated(true);
        setFirebaseId(microsoftResponse.user.uid);
      } else {
        toast.error("Invalid credentials");
        setAuthenticated(false);
      }
    } catch (error) {
      toast.error(error.message);
      setAuthenticated(false);
    }
  };

  const signOutUser = () => {
    firebaseSignOut(auth)
      .then(() => {
        onReset();
      })
      .catch((error) => {
        console.error("Error signing out:", error);
      });
  };

  const onReset = () => {
    setUser(null);
    removeCookie("firebaseId", { path: "/" });
    setAuthenticated(false);
    setFirebaseId(null);
    localStorage.clear();
  };

  const onLoad = async () => {
    if (firebaseId) {
      onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) {
          const payload = {
            firebaseId: currentUser.uid,
            name: currentUser.displayName,
            email: currentUser.email,
            avatar: currentUser.photoURL,
          };

          onFindUserFromFirebase(currentUser.uid, payload);
          localStorage.setItem("firebaseId", currentUser.uid);
        }
      });

      return;
    }

    setLoading(false);
  };

  const onFindUserFromFirebase = async (id, user) => {
    const q = query(collection(db, "users"), where("firebaseId", "==", id));

    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      onAddUserToFirebase(user);
    } else {
      querySnapshot.forEach((doc) => {
        setUser({ id: doc.id, ...doc.data() });
      });
      setLoading(false);
    }
  };

  const onAddUserToFirebase = async ({ firebaseId, name, avatar, email }) => {
    try {
      await addDoc(collection(db, "users"), {
        firebaseId,
        name,
        avatar,
        email,
        paymentStatus: PAYMENT_STATUS.UNPAID,
        paymentDate: null,
        paymentPlan: PAYMENT_PLAN.FREE,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });

      setUser({
        firebaseId,
        name,
        avatar,
        email,
        paymentStatus: PAYMENT_STATUS.UNPAID,
        paymentDate: null,
        paymentPlan: PAYMENT_PLAN.FREE,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      });

      setLoading(false);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const onFindUserVideosFromFirebase = async (userId) => {
    const q = query(collection(db, "videos"), where("userId", "==", userId));

    return await getDocs(q)
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          let data = [];

          querySnapshot.forEach((doc) => {
            data.push({ id: doc.id, ...doc.data() });
          });

          setVideos(sortDataAccordingtoDate(data));
        } else {
          setVideos([]);
        }
      })
      .catch((error) => {
        console.error("Error getting documents: ", error);
      });
  };

  const onUpdateUser = async (data) => {
    const usersRef = collection(db, "users");
    const userQuery = query(
      usersRef,
      where("firebaseId", "==", user.firebaseId)
    );

    const querySnapshot = await getDocs(userQuery);

    if (!querySnapshot.empty) {
      querySnapshot.forEach(async (doc) => {
        const docRef = doc.ref;
        await updateDoc(docRef, { ...data });
        setUser({ ...user, ...data });
        console.log("Document updated successfully");
      });
    } else {
      console.log("No matching documents found");
    }
  };

  return (
    <UserContext.Provider
      value={{
        cookies,
        authenticated,
        loading,
        firebaseId,
        user,
        videos,
        setVideos,
        setCookie,
        setAuthenticated,
        setLoading,
        setFirebaseId,
        setUser,
        onReset,
        onLogIn,
        onSignUp,
        googleSignIn,
        microsoftSignIn,
        signOut: signOutUser,
        onFindUserVideosFromFirebase,
        onUpdateUser,
      }}
    >
      {loading ? <Loader /> : <> {children} </>}
    </UserContext.Provider>
  );
};
